/*
*
  home page styles
*
*/
.homepage-main {
  background: {
    color: $darkerFontBlack;
    image: linear-gradient(
        45deg,
        fade-out(#282823, 0) 0%,
        fade-out(lighten(#282823, 28%), 0.95) 100%
      ),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23eeeeee' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238e8e89' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"),
    -moz-linear-gradient(45deg, #1b1b16, #282823, #5b5b56);
  color: #fff;
  h1,
  h2,
  h3 {
    color: #fff;
  }
  padding: 0 10vw;
}

// image:ease-in-out-quad-gradient(to right, black, transparent), url(../images/homepage-min.jpg);
// image:scrim-gradient(to right, black, transparent), url(../images/homepage-min.jpg);
.homepage-splash {
  height: 0;
  width: 100%;
  position: relative;
  color: #fff;
  padding-bottom: 100vh;

  .homepage-flex-main {
    position: absolute;
    width: 100%;
    height: 100%;
    @include mainFlexContainer;
    align-items: center;

    .homepage-flex-card {
      width: 100%;

      .special-header {
        font-size: 3em;
        font-family: $special-text;
        text-shadow: 4px 6px 6px rgba(0, 0, 0, 0.77);
        margin-bottom: 1rem;

        @media screen and (768px) {
          font-size: 5em;
        }
      }

      .header-subtitle {
        margin-bottom: 8em;
        text-transform: uppercase;
        font-family: $header-text;
        font-weight: 300;
        text-shadow: 4px 6px 6px rgba(0, 0, 0, 0.77);
        letter-spacing: 1px;
        color: $onNeutralFontBlack;
      }

      .homepage-nav--flex-main {
        @include mainFlexContainer;
        justify-content: flex-start;

        .homepage-nav--flex-card {
          width: 100%;

          @media screen and (min-width: 500px) {
            width: auto;
            margin-right: 1.5em;
          }

          @media screen and (min-width: 1123px) {
            margin-right: 1.75em;
          }

          .cta-button {
            color: $lightGray;
            text-transform: uppercase;
            padding: 0.5em 0;
            font-size: 0.85em;
            border: none;
            margin-bottom: 1.5em;
            font-weight: 300;
            border: 1px solid;
            padding: 0.8em 1em;
            text-align: center;
            font-family: $header-text;
            font-weight: 300;

            &:hover {
              color: $accent;
              text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
              box-shadow: 1px 3px 1px 2px rgba(0, 0, 0, 0.2),
                inset 1px 3px 1px 2px rgba(0, 0, 0, 0.2);
            }

            @media screen and (max-width: 500px) {
              display: block;
            }

            @media screen and (min-width: 768px) {
              font-size: 1.15em;
            }
          }
        }
      }
    }
  }
}
