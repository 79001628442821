//*****
//Variables
//*****/
$huskerRed: #d00000;
$secondary: #0097A7 ;
$solidGray: #2d3c49;
$cementGray: #607D8B;
$lightGray: #eee;
$darkGray: #424242;
$lightFontBlack: #757574;
$onNeutralFontBlack: #a7a7a5;
$regularFontBlack: #5b5b5a;
$darkerFontBlack: #282823;
$accent: #FFC107;
$linkColor: #275D8B;
$linkHoverColor: #1C4063;

