@keyframes animatedTabletBackground {
  0% {
    background-size: 175%;
  }
  100% {
    background-size: 210%;
  }
}
@keyframes animatedBackground {
  0% {
    background-size: 140%;
  }
  100% {
    background-size: 164%;
  }
}
@keyframes animatedWideBackground {
  0% {
    background-size: 114%;
  }
  100% {
    background-size: 142%;
  }
}

//Animation Keyframes
@keyframes wiggle {
  4%, 8% {
      -webkit-transform: rotate(14deg);
      transform: rotate(14deg);
    }
  6%, 10% {
      -webkit-transform: rotate(-14deg);
      transform: rotate(-14deg);
    }
  12%, 16% {
      -webkit-transform: rotate(7deg);
      transform: rotate(7deg);
    }
  14%, 18% {
      -webkit-transform: rotate(-7deg);
      transform: rotate(-7deg);
    }
  20%, 24% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  22%, 26% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  28% {

  }
}

@keyframes wiggle-right {
  4%, 8% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
  6%, 10% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
  12%, 16% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
  14%, 18% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
  20%, 24% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0);
    }
  22%, 26% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0);
    }
  28% {
  }
}


//animation move from top offscren to default position
@keyframes fade-in--from-top {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in--from-bottom {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes fade-in--from-left {
  0% {
    transform: translateX(-15%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes fade-in--from-right {
  0% {
    transform: translateX(15%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}


//slide-diagonal-up for page content navigation
@keyframes slide-diagonal-up {
  0% {
    transform: translateX(0) translateY(0) rotate(45deg);
  }
  100% {
    transform: translateX(-150px) translateY(-150px) rotate(45deg);
  }
}

//pop dropcap of landing page
@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);

  }
  100% {
    transform: scale(1);
  }
}


@keyframes dimmer {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}